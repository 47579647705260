// Bootstrap Framework
import './scss/index.scss';
import jquery from 'jquery';
window.jQuery = window.$ = jquery;

import 'popper.js';
import 'bootstrap';

import "slick-carousel";
import 'jquery-match-height';

$(function() {

    //#region menu products
    $('.btn-products').on('click', function(e) {
        e.preventDefault();
        $('.megamenu').toggleClass('megamenu--show');
    });
    //#endregion

    //#region slider
    $('.slider').slick({
        fade: true,
        prevArrow: '.slider__arrow--prev',
        nextArrow: '.slider__arrow--next',
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover: false,
        pauseOnFocus: false
    });
    //#endregion

    //#region ctas heights
    $('.cta').matchHeight({ byRow: false });
    //#endregion

    //#megamenu mobile navigation
    if ($(window).width() < 992) {
        $('.megamenu__link').on('click', function(e) {
            e.preventDefault();
            $(this).next('.megamenu__dropright').toggleClass('megamenu__dropright--show');
        });
    }
    //#endregion
});